import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { VolumeOff, VolumeUp } from "@mui/icons-material";
import { Rating } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { capitalizeFirstChar } from "../utlis/capitalizeFirstChar";

const docInfo = [
  {
    _id: "200",
    image:
      "https://static-3.bitchute.com/live/cover_images/bfccY6uX8kRl/gk22HCZThaVM_320x180.jpg",
    title: "Samadhi - 2 It's not what you think",
    rating: 5,
    duration: 70,
    genre: "spirituality",
    year: 2018,
    description:
      "The world's greatest spiritual teachers from ancient to modern times have shared the view that the deepest truth of our being is not the property of one particular religion or spiritual tradition, but can be found within the heart of each person.",
    tags: ["samadhi", "spirituality", "awake"],
    otherSources: ["https://www.youtube.com/watch?v=AQL6qcGqQ0Y"],
    shareLink:
      "https://z-kkb4jvzl1og7.bitchute.com/RmaN7ihfKGPP/MPjY0Pte9gab.mp4",
  },
  {
    _id: "3",
    image:
      "https://static-3.bitchute.com/live/cover_images/dancpAAhIini/mJfWFvG0G6Yb_320x180.jpg",
    title: "Graham Hancock - The War on Consciousness (2015)",
    rating: 5,
    duration: 18,
    genre: "mystery",
    year: 2015,
    description: "Great presentation by Graham Hancock.",
    tags: ["mystery", "hancock", "science"],
    otherSources: ["https://4sevendocs.com"],
    shareLink: "https://seed132.bitchute.com/dancpAAhIini/mJfWFvG0G6Yb.mp4",
    __v: 0,
    createdAt: "2024-10-16T17:59:59.533Z",
    updatedAt: "2024-10-16T17:59:59.533Z",
    subs: true,
  },
];

export interface IAutoPlayVideo {
  typeOfDocs: string;
}

const AutoPlayVideo: React.FC<IAutoPlayVideo> = ({ typeOfDocs }) => {
  const playerRef = useRef<ReactPlayer | null>(null);
  const [muted, setMuted] = useState(true); // State to manage sound
  const navigate = useNavigate();
  let typeOfTrailer = typeOfDocs === "docs" ? 0 : 1;

  const isMobile = useMediaQuery("(max-width:600px)");

  const handleWatch = () => {
    let movieParam = typeOfDocs === "docs" ? "singleDoc" : "singleTalk";
    navigate(`/${movieParam}/${docInfo[typeOfTrailer]?._id}`);
  };

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.seekTo(0, "seconds"); // Start from the beginning
    }
  }, []);

  const toggleSound = () => {
    setMuted(!muted); // Toggle the muted state
  };

  return (
    <div
      style={{
        position: "relative", // Enable positioning for overlay
        width: "100%",
        marginTop: "-28px",
        height: isMobile ? "270px" : "600px",
        overflow: "hidden",
        zIndex: 2,
      }}
    >
      {/* ReactPlayer for the video */}
      <ReactPlayer
        ref={playerRef}
        url={
          typeOfDocs === "docs"
            ? require("./subs/doc.mp4")
            : require("./subs/talk.mp4")
        }
        playing
        muted={muted} // Control muted state
        loop
        width="100%"
        height="auto"
        playsinline
        config={{
          file: { attributes: { autoPlay: true, muted: muted } },
        }}
      />

      {/* Dark overlay div */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background:
            "linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 88%, rgba(17, 17, 17, 1))", // Gradient from semi-transparent black to white at the bottom
          zIndex: 4, // Hre it's on top of the video
        }}
      >
        {/* Text aligned to top left */}
        <div
          style={{
            position: "absolute",
            top: "20px", // Adjust as needed
            left: "20px", // Adjust as needed
            color: "white",
          }}
        >
          <br />
          <h3>Editor's pick</h3>
          {!isMobile && <br />}
          <h1 style={{ fontSize: isMobile ? "1rem" : "2rem" }}>
            {docInfo[typeOfTrailer]?.title}
          </h1>
          <h3 style={{ fontSize: isMobile ? "0.8rem" : "1.2rem" }}>
            Genre: {capitalizeFirstChar(docInfo[typeOfTrailer]?.genre)}
          </h3>
          <h3 style={{ fontSize: isMobile ? "0.8rem" : "1.2rem" }}>
            Duration: {docInfo[typeOfTrailer]?.duration} min
          </h3>
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <h3 style={{ fontSize: isMobile ? "0.8rem" : "1.2rem" }}>
              Rating:{" "}
            </h3>
            <Rating
              sx={{
                marginTop: "2px",
                marginLeft: "2px",
                fontSize: isMobile ? "14px" : "21px",
              }}
              readOnly
              value={docInfo[typeOfTrailer]?.rating}
            />{" "}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              flexDirection: "row",
              marginTop: isMobile ? "-20px" : "0px",
            }}
          >
            <button
              onClick={handleWatch}
              style={{
                //   position: "absolute",
                //   bottom: "20px", // Adjust as needed
                //   right: "20px", // Adjust as needed
                marginTop: "24px",
                padding: "10px 20px",
                fontSize: isMobile ? "1rem" : "1.5rem",
                color: "white",
                backgroundColor: "rgba(0, 0, 0, 0.7)", // Semi-transparent button
                border: "none",
                cursor: "pointer",
                borderRadius: "5px",
              }}
            >
              Watch documentary
            </button>

            <button
              onClick={toggleSound}
              style={{
                marginTop: "24px",
                marginLeft: "10px",
                padding: isMobile ? "5px 15px" : "10px 20px", // Adjust padding based on isMobile
                fontSize: isMobile ? "0.9rem" : "1rem", // Adjust fontSize based on isMobile
                color: "white",
                backgroundColor: "rgba(0, 0, 0, 0.7)", // Semi-transparent button
                border: "none",
                cursor: "pointer",
                borderRadius: "5px",
              }}
            >
              {muted ? <VolumeOff /> : <VolumeUp />}
            </button>
          </div>
        </div>

        {/* Sound toggle button in bottom right */}
      </div>
    </div>
  );
};

export default AutoPlayVideo;
