import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useNavigate } from "react-router-dom";

interface IAppBarSelect {
  typeOfDocs: string;
  setTypeOfDocs: (typeOfDoc: string) => void;
}

const AppBarSelect: React.FC<IAppBarSelect> = ({
  typeOfDocs,
  setTypeOfDocs,
}) => {
  const navigate = useNavigate();

  const handleChange = (event: SelectChangeEvent) => {
    setTypeOfDocs(event.target.value);
    const currentUrl = window.location.href;

    if (currentUrl.includes("single")) navigate("/");
  };

  const handleClose = () => {
    const currentUrl = window.location.href;

    if (
      currentUrl.includes("single") ||
      currentUrl.includes("subscribe") ||
      currentUrl.includes("top20") ||
      currentUrl.includes("contact")
    ) {
      navigate("/");
    }
  };

  return (
    <FormControl
      sx={{
        backgroundColor: "black",
        marginTop: "-9px",
        color: "white",
        width: "94px",
        height: "40px",
        border: "none", // Removes the border of the FormControl
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none", // Removes the border around the Select component
        },
      }}
    >
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={typeOfDocs}
        label="Select Type"
        onChange={handleChange}
        onClose={handleClose}
        sx={{
          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", // Matches Material-UI Button font
          fontSize: "0.875REM", // Default size for buttons
          fontWeight: "500", // Default weight for Material-UI buttons
          letterSpacing: "0.02857em",
          textTransform: "uppercase", // Like buttons, text is usually uppercase
          backgroundColor: "black",
          color: "white",
          border: "none", // Removes the border of the Select itself
          textShadow: "0 0 15px rgba(255, 255, 255, 0.8)", // Initial glow effect
          animation: "glow 1.5s ease-in-out infinite", // Apply animation to text shadow
          ".MuiSelect-icon": {
            color: "white",
          },
          "@keyframes glow": {
            "0%": { textShadow: "0 0 10px rgba(255, 255, 255, 0.8)" },
            "50%": { textShadow: "0 0 25px rgba(255, 255, 255, 1)" },
            "100%": { textShadow: "0 0 10px rgba(255, 255, 255, 0.8)" },
          },
        }}
      >
        <MenuItem value={"docs"} sx={{ border: "none", fontSize: "0.875REM" }}>
          DOCS
        </MenuItem>
        <MenuItem value={"talks"} sx={{ border: "none", fontSize: "0.875REM" }}>
          TALKS
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default AppBarSelect;
