import React from "react";
import { getRandomLink } from "../utlis/randomLink";

interface AddModalProps {
  isVisible: boolean;
  onClose: () => void;
}

function AddModal({ isVisible, onClose }: AddModalProps) {
  const isMobile = window.innerWidth <= 768;
  const randomAddLink = getRandomLink();

  const handleClick = () => {
    onClose(); // Close the modal after the ad link is clicked
  };

  const modalOverlayStyle: React.CSSProperties = {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "black",
    zIndex: 1000,
    opacity: 0.1,
    cursor: "pointer",
    borderRadius: "6px",
  };

  const modalContainerStyle: React.CSSProperties = {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "10px",
    zIndex: 1001,
    textDecoration: "none",
  };

  const headerStyle: React.CSSProperties = {
    position: "absolute",
    top: 0,
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 1)", // Fully opaque black
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 0", // Adjust padding to give space without fixed height
    fontSize: isMobile ? "12px" : "18px",
    fontWeight: "bold",
    zIndex: 1002,
    marginLeft: 2,
  };

  const textStyle: React.CSSProperties = {
    fontSize: isMobile ? "12px" : "22px",
    color: "white",
    textDecoration: "none",
    textAlign: "center", // Ensure text is centered
    marginTop: isMobile ? "0%" : "20%",
  };

  const textContainerStyle: React.CSSProperties = {
    marginTop: "60px", // Adjust margin top to position below header
    textAlign: "center",
    padding: "5px",
  };

  return (
    <>
      {isVisible && (
        <>
          <div style={modalOverlayStyle} onClick={onClose} />

          <div style={headerStyle}>
            Please wait until the ad loads, it will help us keep the site free
            and minimize ads.
          </div>

          <a
            href={randomAddLink}
            target="_blank"
            rel="noopener noreferrer"
            style={modalContainerStyle}
            onClick={handleClick}
          >
            <div style={anchorStyle}>
              <div style={textContainerStyle}></div>
            </div>
          </a>
        </>
      )}
    </>
  );
}

const addBoxStyle: React.CSSProperties = {
  position: "absolute",
  top: "8px",
  left: "8px",
  backgroundColor: "white",
  color: "black",
  padding: "3px 5px",
  borderRadius: "3px",
  fontSize: "12px",
  fontWeight: "bold",
};

const anchorStyle: React.CSSProperties = {
  textDecoration: "none",
  color: "white",
  cursor: "pointer",
};

export default AddModal;
