import React from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Button,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { sendSubscribe } from "../api/getData";
import Footer from "../components/Footer";

const UserSubscribe: React.FC = () => {
  const [responseMessage, setResponseMessage] = React.useState<string | null>(
    null
  );
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false); // Loading state

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleAction = async (data: any, action: string) => {
    setIsLoading(true); // Start loading
    try {
      const postData = { action, ...data };
      const response = await sendSubscribe(postData);

      if (response && response.message) {
        setResponseMessage(data?.email + ": " + response.message);
        setErrorMessage(null);
        reset();

        // Clear any existing timeout and set new one
        const timeoutId = setTimeout(() => {
          navigate("/");
        }, 5000);

        return () => clearTimeout(timeoutId); // Cleanup timeout on unmount
      } else {
        console.log("Subscription successful, but no message received.");
      }
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message || "An unexpected error occurred.");
        setResponseMessage(null);
      }
    } finally {
      setIsLoading(false); // End loading
    }
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container maxWidth="sm" sx={{ height: "60vh" }}>
        <Typography variant="h4" gutterBottom>
          Subscribe
        </Typography>
        <Typography>
          Subscribe to receive email updates whenever a new documentary is added
          to the site.
        </Typography>

        <form
          onSubmit={handleSubmit((data) => handleAction(data, "subscribe"))}
        >
          {/* Email Input */}
          <TextField
            label="Your Email"
            variant="outlined"
            fullWidth
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
                message: "Enter a valid email address",
              },
            })}
            error={!!errors.email}
            helperText={errors.email ? (errors.email.message as string) : ""}
            margin="normal"
            sx={{
              "& .MuiFormLabel-root": {
                color: "#111111", // Label color
              },
              "& .MuiFormLabel-root.Mui-focused": {
                color: "#111111", // Label color when focused
              },
              "& .MuiOutlinedInput-root": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#111111", // Border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#111111", // Border color on hover
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#111111", // Border color when focused
                },
                "& .MuiInputBase-input": {
                  color: "#111111", // Input text color
                },
              },
            }}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            {/* Subscribe Button */}
            <Button
              type="submit"
              variant="contained"
              style={{ backgroundColor: "#111111" }}
              onClick={handleSubmit((data) => handleAction(data, "subscribe"))}
            >
              {isLoading ? "Loading..." : "Subscribe"}
            </Button>

            {/* Unsubscribe Button */}
            <Button
              type="submit"
              variant="outlined"
              style={{ color: "#111111", borderColor: "#111111" }}
              onClick={handleSubmit((data) =>
                handleAction(data, "unsubscribe")
              )}
              disabled={isLoading} // Disable while loading
            >
              Unsubscribe
            </Button>
          </div>
        </form>
        {responseMessage && (
          <Typography sx={{ color: "green", marginTop: "10px" }}>
            {responseMessage}!
          </Typography>
        )}
        {errorMessage && (
          <Typography sx={{ color: "red", marginTop: "10px" }}>
            {errorMessage}
          </Typography>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default UserSubscribe;
