export function getRandomLink(): string {
  const links = [
    "https://impropermoleculeshilling.com/abjyn9e5?key=ba0dae0f5a27cbdbd8220f7e8e479196",
    "https://impropermoleculeshilling.com/e1tc5geza?key=35ad9410f6bbe85b56a3a668fb44e71e",
    "https://impropermoleculeshilling.com/gdskr2id?key=3687c27e0985df79e7b6dc0c11894f39",
    "https://impropermoleculeshilling.com/tcdwkz0zh?key=b34c02ea18770d80ea1e3f01d6b10598",
    "https://impropermoleculeshilling.com/ych7n7m70h?key=f9f83db0b13dc1aed5325abaddf9a3c1",
  ];

  const randomIndex = Math.floor(Math.random() * links.length);
  return links[randomIndex];
}
