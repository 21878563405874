import axios from "axios";

const url = window.location.origin.includes("localhost")
  ? "http://localhost:3001"
  : "https://fourserver-242b839d63f0.herokuapp.com";
export const getData = async (typeOfDoc: string, docId?: string) => {
  let endpointType = typeOfDoc === "docs" ? "getAllDocs" : "getAllTalks";
  let singleEndpointType =
    typeOfDoc === "docs" ? "getSingleDoc" : "getSingleTalk";

  try {
    let response;
    if (docId)
      response = await axios.get(`${url}/${singleEndpointType}`, {
        params: { docId },
      });
    else response = await axios.get(`${url}/${endpointType}`);

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const sendSubscribe = async (postData: any) => {
  try {
    if (postData) {
      const response = await axios.post(`${url}/subscribe`, { ...postData });
      return response.data; // Ensure we only return the data field
    }
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message); // Safely access the message
    } else {
      throw new Error(err.message || "Network error or server did not respond");
    }
  }
};

export const videoPlayApi = async (videoId: string | undefined) => {
  axios
    .post(`${url}/videoPlayCount`, { videoId })
    .then((response) => {
      console.log("Weekly play count updated:", response.data);
    })
    .catch((error) => {
      console.error("Error updating play count:", error);
    });
};

export const getTop20api = async () => {
  try {
    const top20Data = await axios.get(`${url}/getTop20`);
    return top20Data.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
